import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from 'react-router-dom';
import { isMobileOnly, isDesktop } from 'react-device-detect';

import { appState, aws } from '@aim/common';
// import { useZendesk } from '@aim/components';
import { useUser } from './utils/userGqlHelper';

import Webinar from './pages/Webinar';
import PleaseRotate from './pages/PleaseRotate';

const Routes = () => {
  // useZendesk();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { eventId } = useParams();
  const { getMyEventParticipation } = useUser();
  const [user, setUser] = useState();
  const [isFirstLoadCompleted, setIsFirstLoadCompleted] = useState(false);

  const isPortrait = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    appState.isSidebarOpen.next(false);
    appState.selectedMenuItem.next('congress');
  }, []);

  useEffect(() => {
    const subscription = appState.user.subscribe(setUser);
    return () => subscription.unsubscribe();
  }, []);

  const GetUserData = async () => {
    if (!user) {
      const awsUser = await aws.getAwsUser();
      if (awsUser) {
        const userAndParticipation = await getMyEventParticipation(
          awsUser?.username,
          eventId
        );
        if (userAndParticipation) {
          appState.user.next({
            awsUser: awsUser,
            userAndParticipation: userAndParticipation,
          });
        }
      }
      setIsFirstLoadCompleted(true);
    }
    return;
  };

  const CheckUser = () => {
    try {
      if (!isFirstLoadCompleted) return false;
      if (!user?.awsUser && !user?.userAndParticipation?.participation) {
        history.push(`/events/${eventId}/login`);
      }
    } catch (error) {
      console.error(error);
    }
    return true;
  };

  GetUserData();

  if (!CheckUser()) return null;

  return (
    <Switch>
      <Route exact path={`${path}/:agendaSessionId`}>
        {!isDesktop && isMobileOnly && isPortrait ? (
          <PleaseRotate />
        ) : (
          <Webinar />
        )}
      </Route>
    </Switch>
  );
};

export default Routes;
