import React from 'react';

import { AimTypography, styled } from '@aim/components';

const MessageTypography = styled(AimTypography)({
  opacity: 0.79,
  fontSize: '0.8rem',
  marginTop: 5,
});

const ServiceMessage = ({ message }) => {
  return <MessageTypography textAlign="center">{message}</MessageTypography>;
};

export default ServiceMessage;
