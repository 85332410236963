import React from 'react';

import { AimTypography, theme, styled } from '@aim/components';

const StyledTabHeader = styled('div')({
  color: theme.colors.greyScale.white,
  paddingRight: 25,
  cursor: 'pointer',
});

const StyledTypography = styled(AimTypography)(({ selected }) => ({
  opacity: selected ? 1 : 0.58,
  fontWeight: selected ? 'normal !important' : '300 !important',
}));

const TabHeader = ({ name, isSelected, onClickTabHeader }) => {
  return (
    <StyledTabHeader onClick={onClickTabHeader}>
      <StyledTypography variant="h2" selected={isSelected}>
        {name}
      </StyledTypography>
    </StyledTabHeader>
  );
};

export default TabHeader;
