import React from 'react';
import { uniqBy } from 'lodash';

import { AimTypography, AimRichText, styled } from '@aim/components';
import Grid from '@material-ui/core/Grid';

import Speaker from './sessionInfo/Speaker';

const MainContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 140px)',
});

const SectionContainer = styled('div')({
  marginTop: 10,
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const SessionInfo = ({ session }) => {
  const speechesAttendee = session.speeches.items.flatMap((speech) =>
    speech.attendees.items.map((a) => ({
      ...a,
      speechId: speech.id,
      start: speech.start,
      end: speech.end,
    }))
  );
  const distinctSpeechesAttendee = uniqBy(
    speechesAttendee,
    (a) => a.participation.id
  );
  return (
    <MainContainer>
      <AimTypography variant="h1" style={{ lineHeight: 1 }} margin={0}>
        {session?.name}
      </AimTypography>
      <SectionContainer>
        {session?.description && (
          <AimRichText
            value={JSON.parse(session?.description)}
            readOnly
            variant="transparent"
            editorStyleOverride={{
              minHeight: 'auto',
              maxHeight: 'fit-content',
              overflow: 'hidden !important',
              padding: 0,
              margin: 0,
            }}
            formControlStyle={{ margin: 0 }}
          />
        )}
      </SectionContainer>
      <SectionContainer>
        <Grid container spacing={2}>
          {distinctSpeechesAttendee.map((speaker) => (
            <Grid key={speaker.id} item xs={6}>
              <Speaker {...speaker} />
            </Grid>
          ))}
        </Grid>
      </SectionContainer>
    </MainContainer>
  );
};

export default SessionInfo;
