import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTransition, animated } from 'react-spring';

import { styled, theme } from '@aim/components';

import CloseIcon from '../../components/icons/CloseIcon';

const StyledCloseIconDiv = styled('div')({
  color: theme.colors.greyScale.white,
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  right: 0,
  paddingTop: 10,
  paddingRight: 20,
  zIndex: 2,
});

const ContentContainer = styled('div')({
  position: 'relative',
  marginTop: 24,
});

const containerStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  background:
    'rgba(0,0,0, 0.4) linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box',
  opacity: 1,
  backdropFilter: 'blur(22px)',
  WebkitBackdropFilter: 'blur(22px)',
  overflow: 'hidden',
  zIndex: 1,
};

const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
);

const RightColumn = ({ open, onClose, children }) => {
  const transitions = useTransition(open, null, {
    from: { width: 0 },
    enter: { width: isMobileOnly ? vw : vw * 0.35 },
    leave: { width: 0 },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={{ ...containerStyle, ...props }}>
          <StyledCloseIconDiv>
            <CloseIcon viewBox="5 5 24 24" onClick={onClose} />
          </StyledCloseIconDiv>
          <ContentContainer>{children}</ContentContainer>
        </animated.div>
      )
  );
};

export default RightColumn;
