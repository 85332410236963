import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useIntl } from 'react-intl';
import translation from './../../utils/translation';

import { styled, theme, CustomIntl } from '@aim/components';

import TabHeader from './rightColumnTabs/TabHeader';
import SessionInfo from './rightColumnTabs/SessionInfo';
import QuestionsAnswers from './rightColumnTabs/QuestionsAnswers';
import Social from './rightColumnTabs/Social';

// import Info from './../../components/icons/Info';
import Infosvg from './../../components/icons/Info.svg';
import QAsvg from './../../components/icons/QA.svg';
import Socialsvg from './../../components/icons/Social.svg';

const TabHeaderContainer = styled('div')({
  display: 'flex',
  gap: isMobileOnly ? 5 : 0,
  justifyContent: isMobileOnly ? 'center' : 'flex-start',
  paddingRight: 20,
  paddingLeft: 20,
});

const TabContentContainer = styled('div')({
  color: theme.colors.greyScale.white,
  marginTop: 5,
});

const RightColumnTabs = ({
  messages,
  publish,
  participation,
  session,
  argsTC,
}) => {
  // Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.rightColumnTabs(intl);

  // States
  const [selectedTab, setSelectedTab] = useState(0);

  // Functions
  const onClickTabHeader = (tab) => {
    setSelectedTab(tab);
  };

  const mobileSvgs = [Infosvg, QAsvg, Socialsvg];
  const desktopNames = [
    i18n.tabHeaders.info.label,
    i18n.tabHeaders.qa.label,
    i18n.tabHeaders.social.label,
  ];

  return (
    <>
      <TabHeaderContainer>
        {isMobileOnly
          ? mobileSvgs.map((svg, idx) => (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 48,
                  height: 48,
                  backgroundColor:
                    selectedTab === idx ? '#83898A' : 'transparent',
                }}
              >
                <img
                  src={svg}
                  alt="info"
                  style={{}}
                  onClick={() => onClickTabHeader(idx)}
                />
              </div>
            ))
          : desktopNames.map((name, idx) => (
              <TabHeader
                key={name}
                name={name}
                isSelected={selectedTab === idx}
                onClickTabHeader={() => onClickTabHeader(idx)}
              />
            ))}
      </TabHeaderContainer>
      {selectedTab === 0 ? (
        <TabContentContainer>
          {session && <SessionInfo session={session} />}
        </TabContentContainer>
      ) : null}
      {selectedTab === 1 ? (
        <TabContentContainer>
          <QuestionsAnswers
            i18n={i18n.tabs.qa}
            participation={participation}
            argsTC={argsTC}
          />
        </TabContentContainer>
      ) : null}
      {selectedTab === 2 ? (
        <TabContentContainer>
          <Social
            i18n={i18n.tabs.social}
            messages={messages}
            publish={publish}
            participation={participation}
          />
        </TabContentContainer>
      ) : null}
    </>
  );
};

export default RightColumnTabs;
