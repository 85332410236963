import React, { useState, useRef, useEffect, useCallback } from 'react';

import Reward from 'react-rewards';
import { useParams } from 'react-router-dom';

import { usePubNub } from 'pubnub-react';

import CloseIcon from '../../components/icons/CloseIcon';

import { AimDialog, styled, theme, useChat } from '@aim/components';
import { constants } from '@aim/common';

import AskAQuestion from './AskAQuestion';
import Survey from './Survey';
import LeftButtons from './LeftButtons';
import RightColumn from './RightColumn';
import RightColumnTabs from './RightColumnTabs';
import TopControls from '../../components/TopControls';

import { extractParticipationUserInfo } from './../../utils/utility';
import { useReactions } from './../../utils/reactionGqlHelper';
import { fetchTC } from './../../utils/tcUtility';
import { usePageVisibility } from './../../utils/usePageVisibility';

const CustomDialog = styled(AimDialog)({
  '& .MuiDialog-paperFullScreen': {
    background: '#0000006B 0% 0% no-repeat padding-box',
  },
});

const CloseIconDialog = styled(CloseIcon)({
  fontSize: '40px !important',
  color: theme.colors.greyScale.white,
  stroke: theme.colors.greyScale.white,
  cursor: 'pointer',
  position: 'absolute',
  right: 100,
});

const defaultConfig = {
  fakingRequest: false,
  angle: 90,
  decay: 0.95,
  spread: 35,
  startVelocity: 17,
  elementCount: 15,
  elementSize: 20,
  lifetime: 40,
  zIndex: 10,
  springAnimation: true,
  rewardPunish: 'reward',
  type: 'emoji',
};

const Overlay = ({ msgChannel, uuid, participation, session, argsTC }) => {
  // Params hook
  const { eventId, agendaSessionId } = useParams();

  //State
  const [openRightColumn, setOpenRightColumn] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [lastButtonSelected, setLastButtonSelected] = useState();
  const [surveyAnswerSelected, setSuveryAnswerSelected] = useState(null);
  const [isOpenReactions, setIsOpenReactions] = useState(false);
  const [reactionConfig, setReactionConfig] = useState(defaultConfig);
  const [userMetadata, setUserMetadata] = useState();
  const [isTabActive, setIsTabActive] = useState(true);

  //Refs
  const rewardRef = useRef(null);

  //Effects
  useEffect(() => {
    if (reactionConfig?.emoji) rewardRef.current?.rewardMe();
  }, [reactionConfig]);

  useEffect(() => {
    if (participation) {
      const newMetadata = extractParticipationUserInfo(participation);
      if (
        !participation ||
        JSON.stringify(participation) !== JSON.stringify(newMetadata)
      ) {
        setUserMetadata(newMetadata);
      }
    }
  }, [participation]);

  //Hooks
  usePageVisibility((e) => {
    setIsTabActive(e.target.visibilityState === 'visible');
  });

  //Functions
  const onButtonClick = (item) => {
    switch (item.type) {
      case 'box':
        //open box
        setOpenRightColumn(!openRightColumn);
        //close others
        setIsOpenReactions(false);
        setShowDialog(false);
        break;
      case 'hand':
        //open question dialog
        setShowDialog(true);
        //close others
        setOpenRightColumn(false);
        setIsOpenReactions(false);
        break;
      case 'document':
        //open survey dialog
        setShowDialog(true);
        //close others
        setOpenRightColumn(false);
        setIsOpenReactions(false);
        break;
      case 'heart':
        //open reactions
        setIsOpenReactions(!isOpenReactions);
        //close others
        setOpenRightColumn(false);
        break;

      default:
        break;
    }
  };

  const closeRightColumn = () => {
    setOpenRightColumn(false);
    setLastButtonSelected(null);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setLastButtonSelected(null);
  };

  const handleQuestionSend = async (data) => {
    const input = {
      body: {
        type: 'sendQuestion',
        argsTC: argsTC,
        eventId: eventId,
        sessionId: agendaSessionId,
        ...data,
      },
    };
    await fetchTC(
      input,
      () => {
        setShowDialog(false);
        setLastButtonSelected(null);
      },
      () => {
        setShowDialog(false);
        setLastButtonSelected(null);
      }
    );
  };

  const handleSurveySend = () => {
    setShowDialog(false);
    setLastButtonSelected(null);
  };

  const dialogContent = () => {
    switch (lastButtonSelected?.type) {
      case 'hand':
        return <AskAQuestion onSend={handleQuestionSend} />;
      case 'document':
        return (
          <Survey
            agendaSessionId={agendaSessionId}
            eventId={eventId}
            onSend={handleSurveySend}
            onSelect={setSuveryAnswerSelected}
            selected={surveyAnswerSelected}
            argsTC={argsTC}
          />
        );
      default:
        break;
    }
  };

  //Callbacks
  const handleSignal = useCallback(
    (s) => {
      if (
        !openRightColumn &&
        isTabActive &&
        s?.message &&
        s.message.startsWith('react|')
      ) {
        showReaction(s.message.replace('react|', ''));
      }
    },
    [openRightColumn, isTabActive]
  );

  //Hooks
  const client = usePubNub();
  const { messages, occupancy, publish, publishSignal } = useChat({
    client,
    userUuid: uuid,
    userMetadata: userMetadata,
    isOnline: true,
    presChannel: msgChannel,
    msgChannel,
    onSignal: handleSignal,
    /* onJoin: () => handleJoin(),
    onLeave: () => handleLeave(), */
  });
  const reactionHelper = useReactions();

  const onReactionClick = (data) => {
    publishSignal(`react|${data.id}`, async (res) => {
      if (!res.status.error) {
        try {
          await reactionHelper.create(
            {
              agendaSessionId: session.id,
              participationId: participation.id,
              reaction: data.id,
              timestamp: new Date(),
            },
            false
          );
        } catch (e) {
          console.error(e);
        }
      }
    });
  };

  const showReaction = (data) => {
    const nextReactionConfig = { ...reactionConfig };

    for (const [key, value] of Object.entries(
      constants.AgendaSessionLiveReactions
    )) {
      if (value.id === data) {
        nextReactionConfig.emoji = value.emoji;
        setReactionConfig(nextReactionConfig);
        break;
      }
    }
  };

  /* const handleJoin = () => {
    publish({
      type: 'sys',
      text: 'webinarJoin',
      userInfo: extractParticipationUserInfo(participation),
    });
  };

  const handleLeave = () => {
    publish({
      type: 'sys',
      text: 'webinarLeave',
      userInfo: extractParticipationUserInfo(participation),
    });
  }; */

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          height: '100vh',
          width: '5rem',
          top: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <LeftButtons
          onClick={onButtonClick}
          lastSelected={lastButtonSelected}
          setLastSelected={setLastButtonSelected}
          isOpenReactions={isOpenReactions}
          onReactionClick={onReactionClick}
        />
      </div>
      <RightColumn open={openRightColumn} onClose={closeRightColumn}>
        <RightColumnTabs
          messages={messages}
          publish={publish}
          participation={participation}
          session={session}
          argsTC={argsTC}
        />
      </RightColumn>
      <CustomDialog
        fullScreen={true}
        open={showDialog}
        hideCancelButton
        hideAgreeButton
      >
        <CloseIconDialog viewBox="5 5 24 24" onClick={closeDialog} />
        {dialogContent()}
      </CustomDialog>
      <div
        style={{
          position: 'absolute',
          bottom: 200,
          right: 200,
        }}
      >
        <Reward
          ref={(ref) => {
            rewardRef.current = ref;
          }}
          type={'emoji'}
          config={reactionConfig}
        ></Reward>
      </div>
      <TopControls
        peopleWatching={occupancy}
        hideWatching={openRightColumn}
        hasWebinarCounterUser={session.hasWebinarCounterUser}
      />
    </div>
  );
};

export default React.memo(Overlay);
