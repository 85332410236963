import React from 'react';

export const DocumentIcon = ({ style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={style}
    viewBox="0 0 40 40"
  >
    <g
      id="Ui/Elements/Icons/Poll/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-4" transform="translate(9.000000, 4.000000)">
        <g id="Group-6">
          <path
            d="M11.0769231,0.5 C11.3919607,0.5 11.6784886,0.622564745 11.8921885,0.822657436 C12.21073,1.12091598 12.368102,1.59104904 12.2181673,2.08631842 L12.2181673,2.08631842 L16.3940254,2.08631842 C16.629824,2.08631842 16.8460534,2.17053616 17.0146296,2.31066862 C17.2833884,2.53408004 17.4319855,2.89880955 17.3465339,3.29221521 L17.3465339,3.29221521 L21.0462302,3.29221521 L21.6538462,30.5342878 L1.10761604,31.1483516 L0.5,3.90627938 L4.80731626,3.29221521 C4.72184986,2.8988751 4.87046222,2.53410729 5.13925453,2.31066714 C5.30781842,2.17054431 5.52402877,2.08631842 5.75982076,2.08631842 L5.75982076,2.08631842 L9.93567878,2.08631842 C9.78574414,1.59104904 9.94311614,1.12091598 10.2616577,0.822657436 C10.4753576,0.622564747 10.7618855,0.5 11.0769231,0.5 Z"
            id="Path"
            stroke="#FFFFFF"
          ></path>
          <polygon
            id="Path-2"
            fill="#FFFFFF"
            points="4.26666667 2.95207517 5.02842418 1.99008719 9.26090367 1.99008719 10.7720139 -1.2126596e-13 12.4997922 1.99008719 16.7711602 1.99008719 17.8284242 3.04735119 17.8284242 5.26318219 4.26666667 5.26318219"
          ></polygon>
        </g>
        <g
          id="Group-5"
          transform="translate(4.000000, 10.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Group-3" transform="translate(0.000000, 5.866667)">
            <g id="Group-2">
              <path
                d="M7.81597009e-16,1.94449891 C7.81597009e-16,3.01871673 0.868951747,3.88899781 1.94019348,3.88899781 C3.01143524,3.88899781 3.88038699,3.01871673 3.88038699,1.94449891 C3.88038699,1.77126367 3.74076421,1.63082858 3.56853078,1.63082858 C3.39629734,1.63082858 3.25667457,1.77126367 3.25667457,1.94449891 C3.25667457,2.67164453 2.66756346,3.26165687 1.94019348,3.26165687 C1.21282353,3.26165687 0.623712419,2.67164453 0.623712419,1.94449891 C0.623712419,1.21735358 1.21282353,0.627341237 1.94019348,0.627341237 C2.11242691,0.627341237 2.25204969,0.486906149 2.25204969,0.313670619 C2.25204969,0.140435088 2.11242691,5.7317114e-14 1.94019348,5.7317114e-14 C0.868951747,5.7317114e-14 7.81597009e-16,0.870281383 7.81597009e-16,1.94449891 L7.81597009e-16,1.94449891 Z"
                id="Path"
              ></path>
              <path
                d="M1.84980545,2.47252572 L2.07418717,2.68749031 L2.2676157,2.4439883 L3.84041219,0.46403608 C3.93833281,0.340766271 3.91836133,0.160994306 3.79580462,0.0625041947 C3.67324794,-0.0359861875 3.49451576,-0.0158985697 3.39659513,0.107371239 L2.01794636,1.84451018 L1.65327612,1.49514491 C1.53968584,1.38632195 1.35989454,1.39072241 1.251701,1.50497353 C1.14350747,1.61922464 1.1478824,1.80006189 1.2614727,1.90888486 L1.84980545,2.47252572 L1.84980545,2.47252572 Z"
                id="Path"
              ></path>
            </g>
            <path
              d="M13.3682033,2.62601519 C13.76703,2.62601519 14.0903429,2.30082127 14.0903429,1.89967443 C14.0903429,1.49852725 13.76703,1.17333333 13.3682033,1.17333333 L5.41547295,1.17333333 C5.01664625,1.17333333 4.69333333,1.49852725 4.69333333,1.89967443 C4.69333333,2.30082127 5.01664625,2.62601519 5.41547295,2.62601519 L13.3682033,2.62601519 L13.3682033,2.62601519 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group-3-Copy-2" transform="translate(0.000000, 11.733333)">
            <g id="Group-2">
              <path
                d="M7.81597009e-16,1.94449891 C7.81597009e-16,3.01871673 0.868951747,3.88899781 1.94019348,3.88899781 C3.01143524,3.88899781 3.88038699,3.01871673 3.88038699,1.94449891 C3.88038699,1.77126367 3.74076421,1.63082858 3.56853078,1.63082858 C3.39629734,1.63082858 3.25667457,1.77126367 3.25667457,1.94449891 C3.25667457,2.67164453 2.66756346,3.26165687 1.94019348,3.26165687 C1.21282353,3.26165687 0.623712419,2.67164453 0.623712419,1.94449891 C0.623712419,1.21735358 1.21282353,0.627341237 1.94019348,0.627341237 C2.11242691,0.627341237 2.25204969,0.486906149 2.25204969,0.313670619 C2.25204969,0.140435088 2.11242691,5.7317114e-14 1.94019348,5.7317114e-14 C0.868951747,5.7317114e-14 7.81597009e-16,0.870281383 7.81597009e-16,1.94449891 L7.81597009e-16,1.94449891 Z"
                id="Path"
              ></path>
              <path
                d="M1.84980545,2.47252572 L2.07418717,2.68749031 L2.2676157,2.4439883 L3.84041219,0.46403608 C3.93833281,0.340766271 3.91836133,0.160994306 3.79580462,0.0625041947 C3.67324794,-0.0359861875 3.49451576,-0.0158985697 3.39659513,0.107371239 L2.01794636,1.84451018 L1.65327612,1.49514491 C1.53968584,1.38632195 1.35989454,1.39072241 1.251701,1.50497353 C1.14350747,1.61922464 1.1478824,1.80006189 1.2614727,1.90888486 L1.84980545,2.47252572 L1.84980545,2.47252572 Z"
                id="Path"
              ></path>
            </g>
            <path
              d="M13.3682033,2.62601519 C13.76703,2.62601519 14.0903429,2.30082127 14.0903429,1.89967443 C14.0903429,1.49852725 13.76703,1.17333333 13.3682033,1.17333333 L5.41547295,1.17333333 C5.01664625,1.17333333 4.69333333,1.49852725 4.69333333,1.89967443 C4.69333333,2.30082127 5.01664625,2.62601519 5.41547295,2.62601519 L13.3682033,2.62601519 L13.3682033,2.62601519 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group-3-Copy">
            <g id="Group-2">
              <path
                d="M7.81597009e-16,1.94449891 C7.81597009e-16,3.01871673 0.868951747,3.88899781 1.94019348,3.88899781 C3.01143524,3.88899781 3.88038699,3.01871673 3.88038699,1.94449891 C3.88038699,1.77126367 3.74076421,1.63082858 3.56853078,1.63082858 C3.39629734,1.63082858 3.25667457,1.77126367 3.25667457,1.94449891 C3.25667457,2.67164453 2.66756346,3.26165687 1.94019348,3.26165687 C1.21282353,3.26165687 0.623712419,2.67164453 0.623712419,1.94449891 C0.623712419,1.21735358 1.21282353,0.627341237 1.94019348,0.627341237 C2.11242691,0.627341237 2.25204969,0.486906149 2.25204969,0.313670619 C2.25204969,0.140435088 2.11242691,5.7317114e-14 1.94019348,5.7317114e-14 C0.868951747,5.7317114e-14 7.81597009e-16,0.870281383 7.81597009e-16,1.94449891 L7.81597009e-16,1.94449891 Z"
                id="Path"
              ></path>
              <path
                d="M1.84980545,2.47252572 L2.07418717,2.68749031 L2.2676157,2.4439883 L3.84041219,0.46403608 C3.93833281,0.340766271 3.91836133,0.160994306 3.79580462,0.0625041947 C3.67324794,-0.0359861875 3.49451576,-0.0158985697 3.39659513,0.107371239 L2.01794636,1.84451018 L1.65327612,1.49514491 C1.53968584,1.38632195 1.35989454,1.39072241 1.251701,1.50497353 C1.14350747,1.61922464 1.1478824,1.80006189 1.2614727,1.90888486 L1.84980545,2.47252572 L1.84980545,2.47252572 Z"
                id="Path"
              ></path>
            </g>
            <path
              d="M13.3682033,2.62601519 C13.76703,2.62601519 14.0903429,2.30082127 14.0903429,1.89967443 C14.0903429,1.49852725 13.76703,1.17333333 13.3682033,1.17333333 L5.41547295,1.17333333 C5.01664625,1.17333333 4.69333333,1.49852725 4.69333333,1.89967443 C4.69333333,2.30082127 5.01664625,2.62601519 5.41547295,2.62601519 L13.3682033,2.62601519 L13.3682033,2.62601519 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DocumentIcon;
