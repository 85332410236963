import React from 'react';

import { AimTypography, AimImage, theme, styled } from '@aim/components';

const SpeakerImage = styled(AimImage)({
  objectFit: 'cover',
  width: 160,
  height: 80,
  marginRight: 10,
});

const SpeakerContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 15,
});

const SpeakerAvatar = styled(AimTypography)({
  minWidth: 160,
  minHeight: 80,
  marginRight: 10,
  background: theme.colors.greyScale.grey1,
  position: 'relative',
});

const SpeakerAvatarContent = styled('div')({
  margin: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const Speaker = ({ start, end, description, participation, image }) => {
  return (
    <SpeakerContainer>
      {participation?.image ? (
        <SpeakerImage src={participation.image} alt="speaker-image" />
      ) : (
        <SpeakerAvatar>
          <SpeakerAvatarContent>
            {`${participation.givenName ? participation.givenName[0] : ''}
            ${participation.familyName ? participation.familyName[0] : ''}`}
          </SpeakerAvatarContent>
        </SpeakerAvatar>
      )}
      <div>
        <AimTypography variant="text" margin={0}>
          {`${start} - ${end}`}
        </AimTypography>
        <AimTypography variant="textBold" margin={0}>
          {description}
        </AimTypography>
        <AimTypography variant="text" margin={0}>
          {`${participation?.givenName || ''} ${
            participation?.familyName || ''
          }`}
        </AimTypography>
      </div>
    </SpeakerContainer>
  );
};

export default Speaker;
