import { aws } from '@aim/common';
import { useParticipations } from './participationGqlHelper';

export const useUser = () => {
  const getMyEventParticipation = async (id, eventId) => {
    const res = await useParticipations().getByCognitoIdEventId(id, eventId);

    if (res) {
      const user = {
        id: id,
        email: res.email,
        givenName: res.givenName,
        familyName: res.familyName,
        businessName: res.businessName,
        participation: res,
      };
      return user;
    }
  };
  return { getMyEventParticipation };
};
