import React from 'react';

import Avatar from '@material-ui/core/Avatar';

import { AimTypography, styled, theme } from '@aim/components';

const MainContainer = styled('div')({
  display: 'flex',
});

const Filler = styled('div')({
  flexGrow: 1,
});

const MessageContainer = styled('div')({
  marginTop: 8,
  maxWidth: '70%',
  minWidth: 100,
  minHeight: 55,
  background: `#FFFFFF38 0% 0% no-repeat padding-box`,
  borderRadius: 31,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const MessageTextContainer = styled('div')(({ paddingleft }) => ({
  paddingLeft: paddingleft ? paddingleft : 0,
  display: 'flex',
  flexDirection: 'column',
}));

const InnerContainer = styled('div')({
  display: 'flex',
  paddingLeft: 8,
  paddingRight: 8,
});

const MessageTypography = styled(AimTypography)(
  ({ externalmargin, overrideopacity, overridefontsize }) => ({
    fontSize: overridefontsize ? overridefontsize : '0.850rem',
    margin: externalmargin != null ? externalmargin : 8,
    opacity: overrideopacity != null ? overrideopacity : 1,
    overflowWrap: 'anywhere',
  })
);

const StyledAvatar = styled(Avatar)({
  position: 'absolute !important',
  top: 0,
  left: 0,
  backgroundColor: `${theme.colors.primary.yellow} !important`,
  transform: 'translate(8px, 8px)',
});

const Message = ({ avatar, givenName, familyName, message, isMine }) => {
  return (
    <MainContainer>
      {isMine ? <Filler /> : null}
      <MessageContainer>
        {isMine ? <Filler /> : null}
        <InnerContainer>
          {!isMine ? (
            <StyledAvatar src={avatar} alt={givenName || 'avatar'}>
              {givenName?.[0]}
            </StyledAvatar>
          ) : null}
          <MessageTextContainer paddingleft={!isMine ? 45 : 0}>
            {!isMine ? (
              <MessageTypography
                margin={0}
                overrideopacity={0.7}
                overridefontsize="0.75rem"
              >{`${givenName} ${familyName}`}</MessageTypography>
            ) : null}
            <MessageTypography
              margin={0}
              externalmargin={isMine ? 8 : '-8px 8px 8px 8px'}
            >
              {message}
            </MessageTypography>
          </MessageTextContainer>
        </InnerContainer>
        {!isMine ? <Filler /> : null}
      </MessageContainer>
    </MainContainer>
  );
};

export default Message;
