import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const fetchTC = async (input, onResponse, onError, ignoreLoading) => {
  !ignoreLoading && showLoader();
  try {
    const apiName = 'tcinterface';
    const path = '/tc';
    const res = await aws.standardAPI.post(apiName, path, input);

    onResponse?.(res);
    !ignoreLoading && hideLoader();
  } catch (e) {
    console.error('👎 error - get question', e);
    onError?.(e);
    !ignoreLoading && hideLoader();
  }
};
