import React from 'react';

const BookIcon = ({ style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={style}
    viewBox="0 0 40 40"
  >
    <g
      id="Ui/Elements/Icons/Sidebar/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M30.1538462,5 L9.84615385,5 C8.82655123,5 8,5.9987494 8,7.23076923 L8,31.7692308 C8,33.0012506 8.82655123,34 9.84615385,34 L30.1538462,34 C31.1734488,34 32,33.0012506 32,31.7692308 L32,7.23076923 C32,5.9987494 31.1734488,5 30.1538462,5 Z M10,6 L24,6 L24,6 L24,33 L10,33 C9.44771525,33 9,32.5522847 9,32 L9,7 C9,6.44771525 9.44771525,6 10,6 Z M25,33 L25,6 L30,6 C30.5522847,6 31,6.44771525 31,7 L31,32 C31,32.5522847 30.5522847,33 30,33 L25,33 L25,33 Z"
        id="Shape"
        fill="#FFFFFF"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default BookIcon;
