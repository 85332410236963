import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { minTwoDigits } from './../../utils/utility';
import translation from './../../utils/translation';

import { AimTypography, CustomIntl, theme, Typography } from '@aim/components';

const Countdown = ({
  onComplete,
  initialRemainingTime,
  totalTime,
  isManualManaged,
}) => {
  //Hooks

  const intl = CustomIntl(useIntl());
  const i18n = translation.countdownComponent(intl);
  const [remainingTimeState, setRemainingTimeState] = useState(
    initialRemainingTime
  );

  useEffect(() => {
    const interval = setInterval(
      () => setRemainingTimeState((oldValue) => oldValue - 1),
      1000
    );

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (remainingTimeState < -2) {
      onComplete();
    }
  }, [remainingTimeState]);

  const parseTime = (props) => {
    let nextRemainingTime = isNaN(props.remainingTime)
      ? remainingTimeState
      : props.remainingTime;
    //to prevent negative values
    nextRemainingTime = nextRemainingTime < 0 ? 0 : nextRemainingTime;
    const hours = minTwoDigits(Math.floor(nextRemainingTime / 3600));
    const minutes = minTwoDigits(Math.floor((nextRemainingTime % 3600) / 60));
    const seconds = minTwoDigits(nextRemainingTime % 60);

    return (
      <Grid container>
        <Grid xs={12}>
          <AimTypography
            textAlign="center"
            style={{
              font: 'normal normal bold 58px/59px Roboto',
              color: theme.colors.primary.yellow,
            }}
          >{`${hours}:${minutes}:${seconds}`}</AimTypography>
        </Grid>
        <Grid xs={12}>
          <AimTypography
            textAlign="center"
            style={{
              font: 'normal normal normal 32px/38px Roboto',
              color: theme.colors.greyScale.white,
            }}
          >
            {i18n.title.label}
          </AimTypography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      style={{
        color: theme.colors.greyScale.white,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        width: 'unset',
      }}
    >
      <Grid item xs>
        <CountdownCircleTimer
          isPlaying
          duration={isManualManaged ? null : totalTime}
          size={500}
          strokeLinecap="square"
          initialRemainingTime={initialRemainingTime}
          onComplete={onComplete}
          onUpdate={(props) => console.log('onUpdate', props)}
          colors={['#EB212E', '#FFF']}
          trailColor={theme.colors.primary.yellow}
        >
          {(props) =>
            isManualManaged ? (
              <Typography
                variant="h1"
                textAlign="center"
                margin={2}
                boxStyle={{ color: '#FFFFFF' }}
                style={{ color: '#FFFFFF' }}
              >
                This session may be delayed, please be patient and stay
                connected
              </Typography>
            ) : (
              parseTime(props)
            )
          }
        </CountdownCircleTimer>
      </Grid>
    </Grid>
  );
};

export default Countdown;
