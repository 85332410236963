import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getParticipantByCognitoIdEventId = /* GraphQL */ `
  query ParticipantByCognitoIdEventId(
    $cognitoUserId: ID
    $participationEventId: ModelIDKeyConditionInput
  ) {
    participantByCognitoIdEventId(
      cognitoUserId: $cognitoUserId
      participationEventId: $participationEventId
    ) {
      items {
        id
        cluster
        event {
          id
        }
        givenName
        familyName
        email
        userShowcase {
          profileImage {
            id
            extension
          }
        }
      }
    }
  }
`;

export const useParticipations = () => {
  const getByCognitoIdEventId = (userId, eventId, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getParticipantByCognitoIdEventId,
        variables: {
          cognitoUserId: userId,
          participationEventId: { eq: eventId },
        },
      })
        .then((response) => {
          const participation =
            response.data?.participantByCognitoIdEventId?.items?.[0];
          resolve(participation);
        })
        .catch((e) => {
          console.error('get-participation-by-cognito-id-event-id-webinar', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { getByCognitoIdEventId };
};
