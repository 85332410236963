import React from 'react';

import Grid from '@material-ui/core/Grid';

import HeartIcon from '../../components/icons/Heart';
import BookIcon from '../../components/icons/Book';
import HandIcon from '../../components/icons/Hand';
import DocumentIcon from '../../components/icons/Document';
import Reactions from './Reactions';

import { styled, theme } from '@aim/components';

const ButtonContainer = styled('div')({
  width: 'fit-content',
});

const iconStyle = {
  cursor: 'pointer',
  width: '3.5rem',
  height: '3.5rem',
  margin: '0.25rem 0',
};

const ReactionComponent = ({
  onClick,
  containerStyle,
  isOpenReactions,
  onReactionClick,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ ...containerStyle, cursor: 'pointer' }}>
        <HeartIcon onClick={onClick} style={iconStyle} />
      </div>
      <div style={{ paddingTop: 10 }}>
        <Reactions open={isOpenReactions} onReactionClick={onReactionClick} />
      </div>
    </div>
  );
};

const BookComponent = ({ onClick, containerStyle }) => {
  return (
    <div style={{ display: 'flex', cursor: 'pointer', ...containerStyle }}>
      <BookIcon onClick={onClick} style={iconStyle} />
    </div>
  );
};

const HandComponent = ({ onClick, containerStyle }) => {
  return (
    <div style={{ display: 'flex', cursor: 'pointer', ...containerStyle }}>
      <HandIcon onClick={onClick} style={iconStyle} />
    </div>
  );
};

const DocumentComponent = ({ onClick, containerStyle }) => {
  return (
    <div style={{ display: 'flex', cursor: 'pointer', ...containerStyle }}>
      <DocumentIcon onClick={onClick} style={iconStyle} />
    </div>
  );
};

const buttons = [
  {
    id: 1,
    IconComponent: React.memo(BookComponent),
    type: 'box',
  },
  {
    id: 2,
    IconComponent: React.memo(ReactionComponent),
    type: 'heart',
  },
  {
    id: 3,
    IconComponent: React.memo(HandComponent),
    type: 'hand',
  },
  {
    id: 4,
    IconComponent: React.memo(DocumentComponent),
    type: 'document',
  },
];

const LeftButtonsComponent = ({
  onClick,
  lastSelected,
  setLastSelected,
  isOpenReactions,
  onReactionClick,
}) => {
  //Functions
  const handleButtonClick = (item) => {
    setLastSelected(lastSelected?.type === item.type ? null : item);
    onClick && onClick(item);
  };

  const getBorderColor = (b) =>
    b?.type === lastSelected?.type
      ? theme.colors.primary.yellow
      : 'transparent';

  return (
    <ButtonContainer>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        style={{
          textAlign: 'center',
          borderRadius: 32,
          background: 'rgba(0, 0, 0, 0.2)',
        }}
      >
        {buttons.map((b) => (
          <Grid item key={b.id} xs={12}>
            <b.IconComponent
              htmlColor="white"
              style={iconStyle}
              onClick={() => handleButtonClick(b)}
              containerStyle={{
                border: `1px solid ${getBorderColor(b)}`,
                padding: '.5rem',
              }}
              isOpenReactions={isOpenReactions}
              onReactionClick={onReactionClick}
            />
          </Grid>
        ))}
      </Grid>
    </ButtonContainer>
  );
};

const LeftButtons = React.memo(LeftButtonsComponent);

export default LeftButtons;
