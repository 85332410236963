export default {
  rightColumnTabs: (intl) => ({
    tabHeaders: {
      info: {
        label: intl.formatMessage({
          description: 'tab header info',
          defaultMessage: 'Info',
        }),
      },
      qa: {
        label: intl.formatMessage({
          description: 'tab header qa',
          defaultMessage: 'Q&A',
        }),
      },
      social: {
        label: intl.formatMessage({
          description: 'tab header social',
          defaultMessage: 'Chat',
        }),
      },
    },
    tabs: {
      info: {
        description: {
          label: intl.formatMessage({
            description: 'session info description',
            defaultMessage: 'Description',
          }),
        },
        speakers: {
          label: intl.formatMessage({
            description: 'session info speakers',
            defaultMessage: 'Speakers',
          }),
        },
      },
      qa: {
        questions: {
          label: intl.formatMessage({
            description: 'qa questions',
            defaultMessage: 'Questions',
          }),
        },
        question: {
          mine: {
            label: intl.formatMessage({
              description: 'qa question mine',
              defaultMessage: 'You',
            }),
          },
        },
      },
      social: {
        serviceMessages: {
          webinarJoin: {
            label: intl.formatMessage({
              description: 'social service message join',
              defaultMessage: 'joined the webinar',
            }),
          },
          webinarLeave: {
            label: intl.formatMessage({
              description: 'social service message leave',
              defaultMessage: 'left the webinar',
            }),
          },
        },
        messageInput: {
          emojiPicker: {
            title: intl.formatMessage({
              description: 'emoji picker title',
              defaultMessage: 'Pick your emoji...',
            }),
            i18n: {
              search: intl.formatMessage({
                description: 'emoji picker search',
                defaultMessage: 'Search',
              }),
              clear: intl.formatMessage({
                description: 'emoji picker clear',
                defaultMessage: 'Clear',
              }),
              notfound: intl.formatMessage({
                description: 'emoji picker not found',
                defaultMessage: 'No Emoji Found',
              }),
              skintext: intl.formatMessage({
                description: 'emoji picker skin text',
                defaultMessage: 'Choose your default skin tone',
              }),
              categories: {
                search: intl.formatMessage({
                  description: 'emoji picker categories search',
                  defaultMessage: 'Search Results',
                }),
                recent: intl.formatMessage({
                  description: 'emoji picker categories recent',
                  defaultMessage: 'Frequently Used',
                }),
                smileys: intl.formatMessage({
                  description: 'emoji picker categories smileys',
                  defaultMessage: 'Smileys & Emotion',
                }),
                people: intl.formatMessage({
                  description: 'emoji picker categories people',
                  defaultMessage: 'People & Body',
                }),
                nature: intl.formatMessage({
                  description: 'emoji picker categories nature',
                  defaultMessage: 'Animals & Nature',
                }),
                foods: intl.formatMessage({
                  description: 'emoji picker categories foods',
                  defaultMessage: 'Food & Drink',
                }),
                activity: intl.formatMessage({
                  description: 'emoji picker categories activity',
                  defaultMessage: 'Activity',
                }),
                places: intl.formatMessage({
                  description: 'emoji picker categories places',
                  defaultMessage: 'Travel & Places',
                }),
                objects: intl.formatMessage({
                  description: 'emoji picker categories objects',
                  defaultMessage: 'Objects',
                }),
                symbols: intl.formatMessage({
                  description: 'emoji picker categories symbols',
                  defaultMessage: 'Symbols',
                }),
                flags: intl.formatMessage({
                  description: 'emoji picker categories flags',
                  defaultMessage: 'Flags',
                }),
                custom: intl.formatMessage({
                  description: 'emoji picker categories custom',
                  defaultMessage: 'Custom',
                }),
              },
              categorieslabel: intl.formatMessage({
                description: 'emoji picker categories label',
                defaultMessage: 'Emoji categories',
              }),
              skintones: {
                1: intl.formatMessage({
                  description: 'emoji picker default skin tone',
                  defaultMessage: 'Default Skin Tone',
                }),
                2: intl.formatMessage({
                  description: 'emoji picker light skin tone',
                  defaultMessage: 'Light Skin Tone',
                }),
                3: intl.formatMessage({
                  description: 'emoji picker medium-light skin tone',
                  defaultMessage: 'Medium-Light Skin Tone',
                }),
                4: intl.formatMessage({
                  description: 'emoji picker medium skin tone',
                  defaultMessage: 'Medium Skin Tone',
                }),
                5: intl.formatMessage({
                  description: 'emoji picker medium-dark skin tone',
                  defaultMessage: 'Medium-Dark Skin Tone',
                }),
                6: intl.formatMessage({
                  description: 'emoji picker dark skin tone',
                  defaultMessage: 'Dark Skin Tone',
                }),
              },
            },
          },
          error: {
            label: intl.formatMessage({
              description: 'chat message input error',
              defaultMessage: 'Error, try again',
            }),
          },
        },
      },
    },
  }),
  surveyDialogContent: (intl) => ({
    buttons: {
      send: {
        label: intl.formatMessage({
          description: 'survey send button',
          defaultMessage: 'Send',
        }),
      },
    },
    alert: {
      noMoreQuestion: {
        label: intl.formatMessage({
          description: 'no more question label',
          defaultMessage: 'No more question avalaible',
        }),
      },
    },
  }),
  askAQuestionDialog: (intl) => ({
    text: {
      title: {
        label: intl.formatMessage({
          description: 'ask a qustion title',
          defaultMessage: 'Ask A Question',
        }),
      },
      isAnonymousText: {
        label: intl.formatMessage({
          description: 'is anonymous text',
          defaultMessage: 'I want to remain anonymous',
        }),
      },
    },
  }),
  endOverlay: (intl) => ({
    firstMessage: {
      label: intl.formatMessage({
        description: 'end overlay first message',
        defaultMessage: 'Session ended. Thanks for participating!',
      }),
    },
    secondMessage: {
      label: intl.formatMessage({
        description: 'end overlay second message',
        defaultMessage: 'Keep watching:',
      }),
    },
    goToSessionsButton: {
      label: intl.formatMessage({
        description: 'end overlay go to session button',
        defaultMessage: 'GO TO ALL SESSIONS',
      }),
    },
  }),
  topControls: (intl) => ({
    buttons: {
      exit: {
        label: intl.formatMessage({
          description: 'top controls exit button',
          defaultMessage: 'EXIT',
        }),
      },
    },
  }),
  countdownComponent: (intl) => ({
    title: {
      label: intl.formatMessage({
        description: 'countdown title',
        defaultMessage: 'Event starting soon',
      }),
    },
  }),
};
