import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createAgendaSessionReaction = /* GraphQL */ `
  mutation CreateAgendaSessionReaction(
    $input: CreateAgendaSessionReactionInput!
    $condition: ModelAgendaSessionReactionConditionInput
  ) {
    createAgendaSessionReaction(input: $input, condition: $condition) {
      id
    }
  }
`;

export const useReactions = () => {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createAgendaSessionReaction,
        variables: { input },
      })
        .then((response) => {
          resolve(response.data.createAgendaSessionReaction);
        })
        .catch((e) => {
          console.error('create-reaction', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { create };
};
