import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';

import translation from './../../utils/translation';

import {
  AimTextField,
  AimCheckbox,
  AimTypography,
  CustomIntl,
  styled,
  theme,
} from '@aim/components';

const TransparentAimTextField = styled(AimTextField)({
  backgroundColor: 'transparent',
  borderBottom: `1px solid ${theme.colors.greyScale.white}`,
  borderRadius: 0,
  '& .MuiInput-input': {
    color: 'black',
  },
});

const TransparentAimCheckBox = styled(AimCheckbox)({
  '& .MuiSvgIcon-root': {
    background: 'transparent !important',
    border: '1px solid white',
    borderRadius: 2,
    width: 15,
    height: 15,
  },
});

const formControlStyle = { width: 'calc(35%)' };

const AskAQuestion = ({ onSend }) => {
  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.askAQuestionDialog(intl);

  //State
  const [question, setQuestion] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  //Functions
  const handleQuestionChange = (data) => {
    setQuestion(data);
  };

  const toggleAnonymous = () => {
    setIsAnonymous(!isAnonymous);
  };

  const onSendQuestion = () => {
    const data = { question: question, isAnonymous: isAnonymous };
    onSend && onSend(data);
  };

  return (
    <Grid
      container
      style={{
        color: theme.colors.greyScale.white,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        textAlign: 'center',
      }}
    >
      <Grid item xs={12}>
        <AimTypography
          style={{ font: 'normal normal 300 54px/36px Roboto' }}
          textAlign="center"
        >
          {i18n.text.title.label}
        </AimTypography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '2%', paddingBottom: '1%' }}>
        <TransparentAimTextField
          formControlStyle={formControlStyle}
          onChange={(e) => handleQuestionChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SendIcon
                  // htmlColor="white"
                  style={{ cursor: 'pointer' }}
                  onClick={() => onSendQuestion()}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AimTypography
          style={{ font: 'normal normal 300 20px/36px Roboto' }}
          textAlign="center"
        >
          {i18n.text.isAnonymousText.label}{' '}
          <TransparentAimCheckBox
            onClick={toggleAnonymous}
            checked={isAnonymous}
          />
        </AimTypography>
      </Grid>
    </Grid>
  );
};

export default AskAQuestion;
