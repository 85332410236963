import React from 'react';

export const ThumbupIcon = ({ style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={style}
    viewBox="0 0 40 40"
  >
    <g
      id="Ui/Elements/Icons/Reaction/Like/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Group"
        transform="translate(8.000000, 8.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <path
          d="M5.6235,24 C4.79416667,24 4.17216667,24 3.7575,24 C3.34283333,24 2.72083333,24 1.8915,24 C0.85725,24 0.01575,23.1585 0.01575,22.1235 L0.01575,10.8765 C0.01575,9.84225 0.85725,9 1.8915,9 L5.6235,9 C6.65775,9 7.5,9.84225 7.5,10.8765 L7.5,22.1235 C7.5,23.1585 6.65775,24 5.6235,24 Z M1.8915,10.5 C1.6845,10.5 1.51575,10.66875 1.51575,10.8765 L1.51575,22.1235 C1.51575,22.3305 1.6845,22.5 1.8915,22.5 L5.6235,22.5 C5.83125,22.5 6,22.3305 6,22.1235 L6,10.8765 C6,10.66875 5.83125,10.5 5.6235,10.5 L1.8915,10.5 Z"
          id="Shape"
        ></path>
        <path
          d="M19.37025,24 L10.28025,24 C9.8175,24 9.35475,23.8905 8.94,23.6835 L6.414,22.4205 C6.0435,22.23525 5.8935,21.7845 6.07875,21.414 C6.264,21.0435 6.714,20.8935 7.08525,21.07875 L9.61125,22.34175 C9.81825,22.44525 10.04925,22.5 10.28025,22.5 L19.37025,22.5 C19.644,22.5 19.899,22.35225 20.0355,22.11375 L20.88225,20.63175 C21.9405,18.78 22.5,16.6725 22.5,14.5395 L22.5,12.0015 C22.5,11.1735 21.8265,10.5 20.9985,10.5 L15.35325,10.5 C14.856,10.5 14.3925,10.254 14.1135,9.84225 C13.83525,9.4305 13.779,8.9085 13.9635,8.44725 C14.65125,6.72825 15,4.917 15,3.06375 L15,2.1945 C15,1.812 14.68875,1.5 14.3055,1.5 L13.839,1.5 C13.53075,1.5 13.25625,1.707 13.1715,2.00325 L12.8775,3.0315 C12.2475,5.23425 11.20275,7.25775 9.77175,9.04725 C9.03375,9.9705 7.93275,10.5 6.75,10.5 L4.5,10.5 C4.086,10.5 3.75,10.164 3.75,9.75 C3.75,9.336 4.086,9 4.5,9 L6.75,9 C7.47375,9 8.14875,8.67525 8.601,8.10975 C9.90675,6.47625 10.86075,4.629 11.43525,2.619 L11.72925,1.59075 C11.997,0.65475 12.86475,0 13.839,0 L14.3055,0 C15.516,0 16.5,0.984 16.5,2.1945 L16.5,3.06375 C16.5,5.109 16.1145,7.10775 15.35625,9.0045 L20.9985,9 C22.65375,9 24,10.34625 24,12.0015 L24,14.5395 C24,16.9335 23.37225,19.29825 22.18425,21.3765 L21.3375,22.8585 C20.93475,23.56275 20.181,24 19.37025,24 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);

export default ThumbupIcon;
