import React, { useState, useEffect } from 'react';

import { appState } from '@aim/common';
import { AimTypography, styled } from '@aim/components';
import { useParams } from 'react-router-dom';

import Question from './questionAnswers/Question';
import { utcToZonedTime, format } from 'date-fns-tz';

const currentTimezone = appState.eventInfo.getValue().timezone;

import { fetchTC } from './../../../utils/tcUtility';

const MainContainer = styled('div')({
  paddingRight: 30,
  paddingLeft: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 140px)',
});

const QuestionsAnswers = ({ i18n, participation, argsTC }) => {
  const { eventId, agendaSessionId } = useParams();

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    const input = {
      body: {
        type: 'getQuestions',
        argsTC: argsTC,
        idEvent: eventId,
        idSession: agendaSessionId,
      },
    };
    await fetchTC(input, (res) => {
      const parsedQuestion = res?.result?.map((i) => {
        const zonedDate = utcToZonedTime(new Date(i.data), currentTimezone);

        const formatData = i.data ? format(zonedDate, 'HH:mm') : '-';

        return {
          id: i.id,
          time: formatData,
          question: i.question,
          givenName: i.name,
          familyName: i.surname,
          isMine: i.idUser === participation.id,
        };
      });
      setQuestions(parsedQuestion);
    });
  };

  const filteredQuestions = questions.filter((q) => q.isMine);

  return (
    <MainContainer>
      <AimTypography variant="h4" margin={0}>
        {i18n.questions.label}
      </AimTypography>
      {filteredQuestions.map(
        ({ id, time, question, givenName, familyName, isMine }) => (
          <Question
            key={id}
            i18n={i18n.question}
            isMine={isMine}
            {...{ time, question, givenName, familyName }}
          />
        )
      )}
    </MainContainer>
  );
};

export default QuestionsAnswers;
