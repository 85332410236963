import React from 'react';

export const HandIcon = ({ style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={style}
    viewBox="0 0 40 40"
  >
    <g
      id="Ui/Elements/Icons/Hand/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M20.2050353,5.4 C20.7075122,5.4 21.2082633,5.58867708 21.5645618,5.92792409 C21.9116481,6.25830146 22.1688698,6.71953064 22.1688698,7.45007343 L22.1688698,7.45007343 L22.168,8.777 L22.185201,8.75755769 L22.185201,8.75755769 L22.2766335,8.66471446 C22.6361773,8.32185603 23.1195664,8.13642752 23.6256001,8.13642752 C24.1303151,8.13642752 24.627285,8.32307325 24.9856186,8.66476225 C25.3331523,8.99624446 25.5894349,9.46466626 25.5894349,10.1981914 L25.5894349,10.1981914 L25.589,12.68 L25.5978567,12.6717287 L25.5978567,12.6717287 L25.6975977,12.5701985 C26.0539995,12.2309058 26.5547387,12.0422483 27.0571785,12.0422483 C27.5610063,12.0422483 28.0481186,12.2296946 28.4057932,12.5701985 C28.7528233,12.9005695 29.01,13.361787 29.01,14.0923185 L29.01,14.0923185 L29.01,24.7573484 C29.01,27.9188526 27.9047253,30.221958 26.2719384,31.7757383 C24.6282318,33.3399814 22.4169017,34.139586 20.2050353,34.139586 C17.9929185,34.139586 15.7930523,33.3401919 14.1490981,31.7757493 C12.5162886,30.2219592 11.4,27.9186021 11.4,24.7573484 L11.4,24.7573484 L11.4,18.1852396 C11.4,17.4562645 11.657418,16.9804561 12.0050509,16.6507216 C12.3627686,16.3113687 12.8591417,16.1234856 13.3638349,16.1234856 C13.7564071,16.1234856 14.1438977,16.2371349 14.465058,16.4473098 L14.521,16.488 L14.8316294,10.1709948 C14.8349335,9.51631171 15.0476984,9.0674277 15.3444484,8.74551692 L15.4358675,8.65267405 C15.7922719,8.31337877 16.2929655,8.12472385 16.7954252,8.12472385 C17.2992388,8.12472385 17.7863394,8.31216763 18.1440189,8.65267667 L18.1440189,8.65267667 L18.178,8.687 L18.2522464,7.43145284 C18.2561873,6.7771269 18.4679328,6.34062966 18.764904,6.02057943 L18.8564295,5.92795639 C19.2141056,5.58744577 19.7012043,5.4 20.2050353,5.4 Z"
        id="Path"
        stroke="#FFFFFF"
        strokeWidth="1.2"
      ></path>
    </g>
  </svg>
);

export default HandIcon;
