import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const CloseIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        fontSize: 40,
        ...props.style,
      }}
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
    >
      <g
        id="Ui/Elements/Icons/Close/White"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
          points="32 8.75350701 31.246493 8 20 19.243821 8.75350701 8 8 8.75350701 19.243821 20 8 31.246493 8.75350701 32 20 20.756179 31.246493 32 32 31.246493 20.756179 20"
        ></polygon>
      </g>
    </SvgIcon>
  );
};

export default CloseIcon;
