import React from 'react';
// import SvgIcon from '@material-ui/core/SvgIcon';

export const HeartIcon = ({ style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={style}
    viewBox="0 0 40 40"
  >
    <g
      id="Ui/Elements/Icons/Reaction/White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M14.2608696,10 C11.5568287,10 8,12.379827 8,17.1820652 C8,19.8395774 9.70947226,22.3491739 11.9945652,24.576087 C14.2796582,26.803 17.1771401,28.763787 19.7309783,30.2744565 C19.8964992,30.3740523 20.1035008,30.3740523 20.2690217,30.2744565 C22.8228602,28.763787 25.7203418,26.803 28.0054348,24.576087 C30.2905277,22.3491739 32,19.8395774 32,17.1820652 C32,12.379827 28.4431713,10 25.7391304,10 C22.9433861,10 21.1361775,11.2759313 20,12.7961957 C18.8638225,11.2759313 17.0566139,10 14.2608696,10 Z M14.2608696,11.0434783 C17.0770757,11.0434783 18.6060059,12.3673913 19.5516304,13.9211957 C19.6457126,14.079251 19.8160627,14.1760958 20,14.1760958 C20.1839373,14.1760958 20.3542874,14.079251 20.4483696,13.9211957 C21.3939941,12.3673913 22.9229243,11.0434783 25.7391304,11.0434783 C27.829819,11.0434783 30.9565217,12.8911365 30.9565217,17.1820652 C30.9565217,19.3800896 29.459792,21.6937391 27.2717391,23.826087 C25.158723,25.885287 22.4448484,27.7433043 20,29.2065217 C17.5551518,27.7433043 14.8412772,25.885287 12.7282609,23.826087 C10.540208,21.6937391 9.04347826,19.3800896 9.04347826,17.1820652 C9.04347826,12.8911365 12.170181,11.0434783 14.2608696,11.0434783 Z"
        id="Shape"
        fill="#FFFFFF"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default HeartIcon;
