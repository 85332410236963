import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getAgendaSession = /* GraphQL */ `
  query GetAgendaSession($id: ID!) {
    getAgendaSession(id: $id) {
      id
      name
      description
      start
      end
      linkPax
      manualManagementStatus
      hasWebinarCounterUser
      coverImage {
        id
        extension
      }
      speeches {
        items {
          instructions
          id
          start
          end
          title
          type
          titleDescription
          abstract {
            id
            title
            status
            presenter {
              participant {
                id
                givenName
                familyName
                userShowcase {
                  profileImage {
                    id
                    extension
                    originalName
                    size
                  }
                }
              }
            }
          }
          attendees {
            items {
              participation {
                id
                givenName
                familyName
                userShowcase {
                  profileImage {
                    id
                    extension
                    originalName
                    size
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const onUpdateAgendaSessionSubscription = /* GraphQL */ `
  subscription OnUpdateAgendaSessionId($id: ID!) {
    onUpdateAgendaSessionId(id: $id) {
      id
    }
  }
`;

export const useAgendaSessions = () => {
  const get = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getAgendaSession,
        variables: { id },
      })
        .then((response) => {
          resolve(response.data.getAgendaSession);
        })
        .catch((e) => {
          console.error('get-agenda-session', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { get };
};

export const onUpdateAgendaSessionById = (id, callback) => {
  return aws.standardAPI
    .graphql({
      query: onUpdateAgendaSessionSubscription,
      variables: { id },
    })
    .subscribe({
      next: ({ value }) => callback(value.data.onUpdateAgendaSessionId),
      error: (e) => {
        console.error('on-update-agenda-session-id', e);
      },
    });
};
