import React, { useState, useEffect, useCallback } from 'react';

import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import { useParams } from 'react-router-dom';

import Overlay from './webinar/Overlay';
import CountdownOverlay from './webinar/CountdownOverlay';
import EndSessionOverlay from './webinar/EndOverlay';
import ImageBackground from './../components/ImageBackground';
import { utcToZonedTime } from 'date-fns-tz';

import {
  useAgendaSessions,
  onUpdateAgendaSessionById,
} from './../utils/agendaSessionGqlHelper';

import { differenceInSeconds, startOfToday, format } from 'date-fns';

import { aws, appState, constants, fileHelper } from '@aim/common';

const currentTimezone = appState.eventInfo.getValue().timezone;

const getSecondsFromNow = (date) => {
  const now = utcToZonedTime(new Date(), currentTimezone);
  return differenceInSeconds(date, now);
};

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

export const sortBy = (array, fieldName) => {
  return array
    ? array.sort((a, b) => {
        if (a[fieldName] < b[fieldName]) return -1;
        if (b[fieldName] > a[fieldName]) return 1;
        return 0;
      })
    : [];
};

const getIntervetionImage = async (eventId, participation) => {
  if (participation?.userShowcase?.profileImage?.id) {
    return fileHelper.getPublicFileLink({
      dirPath: `events/${eventId}/user/${participation.id}/showcase/profileImage/`,
      skipFileDataOnS3Link: true,
    });
  } else {
    return null;
  }
};

const Webinar = () => {
  // Hooks
  const { eventId, agendaSessionId } = useParams();
  const agendaSessionHelper = useAgendaSessions();

  // States
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [user, setUser] = useState();
  const [participation, setParticipation] = useState();
  const [session, setSession] = useState();
  const [client, setClient] = useState();
  const [clientData, setClientData] = useState({
    uuid: null,
    msgChannel: null,
  });
  const [secondRemainingStart, setSecondRemainingStart] = useState();
  const [secondRemainingEnd, setSecondRemainingEnd] = useState();
  const [argsTC, setArgsTC] = useState();

  //constants
  const s3FolderCover = `events/${eventId}/agendaSessions/${agendaSessionId}/coverImage/`;

  // Effects

  const [eventConfiguration, setEventConfiguration] = useState({});
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    const getImages = async () => {
      if (eventConfiguration?.welcomePageCoverImage) {
        const link = await fileHelper.getAwsS3Link(
          `events/${eventId}/configuration/welcomePageCoverImage`,
          eventConfiguration.welcomePageCoverImage
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setCoverImage(convertedLink);
      }
    };

    getImages();
  }, [eventConfiguration]);
  useEffect(() => {
    if (end) {
      const secondsToEnd = getSecondsFromNow(end);
      const interval = setTimeout(() => {
        setSecondRemainingEnd(-1);
      }, secondsToEnd * 1000);
      return () => clearTimeout(interval);
    }
  }, [end]);

  useEffect(() => {
    const userSubscription = appState.user.subscribe(setUser);
    const configurationSubscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => {
      userSubscription.unsubscribe();
      configurationSubscription.unsubscribe();
    };
  }, []);

  // Callbacks
  const subscriptionCallback = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!user) return;

    fetchData();
    const subscriptionSessionUpdate = onUpdateAgendaSessionById(
      agendaSessionId,
      subscriptionCallback
    );

    return () => subscriptionSessionUpdate.unsubscribe();
  }, [user]);

  useEffect(() => {
    if (participation?.id && session?.id) {
      initChatClient();
    }
  }, [participation, session]);

  // Functions
  const counterTotalTime = differenceInSeconds(start, startOfToday());

  const getUserParticipation = async () => {
    try {
      const nextParticipation = user?.userAndParticipation?.participation;

      nextParticipation.profileImage = nextParticipation?.userShowcase
        ?.profileImage?.id
        ? fileHelper.getPublicFileLink({
            dirPath: `events/${eventId}/user/${nextParticipation.id}/showcase/profileImage/`,
            skipFileDataOnS3Link: true,
          })
        : null;

      setParticipation(nextParticipation);
    } catch (err) {
      console.error(err);
    }
  };

  const getAgendaSession = async () => {
    try {
      const nextSession = await agendaSessionHelper.get(agendaSessionId, false);
      nextSession.backgroundImage = nextSession?.coverImage?.id
        ? await aws.Storage.get(
            `${s3FolderCover}${nextSession?.coverImage?.id}${nextSession?.coverImage?.extension}`,
            { expires: 43200 }
          )
        : null;

      const nextSpeeches =
        (await Promise.all(
          nextSession.speeches?.items?.map(async (speech) => ({
            ...speech,
            start: format(
              utcToZonedTime(new Date(speech.start), currentTimezone),
              'HH:mm'
            ),
            end: format(
              utcToZonedTime(new Date(speech.end), currentTimezone),
              'HH:mm'
            ),
            attendees: {
              items: speech.abstract
                ? await Promise.all([
                    {
                      participation: {
                        ...speech?.abstract?.presenter?.participant,
                        image: await getIntervetionImage(
                          eventId,
                          speech?.abstract?.presenter?.participant
                        ),
                      },
                    },
                  ])
                : await Promise.all(
                    speech.attendees.items.map(async (a) => ({
                      ...a,
                      participation: {
                        ...a.participation,
                        image: await getIntervetionImage(
                          eventId,
                          a.participation
                        ),
                      },
                    }))
                  ),
            },
          }))
        )) || [];

      setSession({ ...nextSession, speeches: { items: nextSpeeches } });

      const nextArgsTC = encodeURIComponent(
        new URL(nextSession.linkPax).searchParams.get('argsTC')
      );
      setArgsTC(nextArgsTC);

      // initialize page
      const zonedStart = utcToZonedTime(
        new Date(nextSession.start),
        currentTimezone
      );
      const zonedEnd = utcToZonedTime(
        new Date(nextSession.end),
        currentTimezone
      );

      setStart(zonedStart);
      setEnd(zonedEnd);
      setSecondRemainingStart(getSecondsFromNow(zonedStart));
      setSecondRemainingEnd(getSecondsFromNow(zonedEnd));

      if (nextSession.symposium?.buyOperation?.sponsor) {
        const sponsor = nextSession.symposium?.buyOperation?.sponsor;
        window.dataLayer.push({
          event: 'sponsored-session',
          agenda_session_id: nextSession?.id,
          agenda_session_name: nextSession?.name,
          sponsor_id: sponsor?.id,
          sponsor_name: sponsor?.name,
          event_id: eventId,
          event_name: appState.eventInfo.getValue()?.name,
          username: user?.userAndParticipation?.participation?.username,
          name: user?.userAndParticipation?.participation?.givenName,
          surname: user?.userAndParticipation?.participation?.familyName,
          email: user?.userAndParticipation?.participation?.email,
        });
      } else {
        window.dataLayer.push({
          event: 'live-session',
          agenda_session_id: nextSession?.id,
          agenda_session_name: nextSession?.name,
          event_id: eventId,
          event_name: appState.eventInfo.getValue()?.name,
          username: user?.userAndParticipation?.participation?.username,
          name: user?.userAndParticipation?.participation?.givenName,
          surname: user?.userAndParticipation?.participation?.familyName,
          email: user?.userAndParticipation?.participation?.email,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = async () => {
    showLoader();
    await getAgendaSession();
    await getUserParticipation();
    hideLoader();
  };

  const initChatClient = async () => {
    const newClientData = {
      uuid: participation.id,
      msgChannel: session.id,
    };
    setClientData(newClientData);

    setClient(
      new PubNub({
        publishKey: process.env.PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
        uuid: newClientData.uuid,
      })
    );
  };

  return participation && session ? (
    <div style={{ height: '100vh', width: '100vw' }}>
      <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
        {session?.manualManagementStatus ===
          constants.agendaSessionManualManagementStatuses.STARTED ||
        ((session?.manualManagementStatus === null ||
          session?.manualManagementStatus ===
            constants.agendaSessionManualManagementStatuses
              .AUTOMATIC_MANAGED) &&
          secondRemainingStart != null &&
          secondRemainingStart <= 0 &&
          secondRemainingEnd != null &&
          secondRemainingEnd > 0) ? (
          <iframe
            src={`${session?.linkPax}&idSession=${session?.id}&idUser=${participation?.id}`}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            width="100%"
            height="100%"
            scrolling="no"
          ></iframe>
        ) : null}
        {session?.manualManagementStatus !==
          constants.agendaSessionManualManagementStatuses.STARTED &&
        (session?.manualManagementStatus ===
          constants.agendaSessionManualManagementStatuses.MANUAL_MANAGED ||
          session?.manualManagementStatus ===
            constants.agendaSessionManualManagementStatuses.STOPPED ||
          (secondRemainingStart != null && secondRemainingStart > 0) ||
          (secondRemainingEnd != null && secondRemainingEnd <= 0)) ? (
          <ImageBackground
            src={session?.backgroundImage || coverImage}
            showShadow
          />
        ) : null}
      </div>

      {session?.manualManagementStatus ===
        constants.agendaSessionManualManagementStatuses.STARTED ||
      ((session?.manualManagementStatus === null ||
        session?.manualManagementStatus ===
          constants.agendaSessionManualManagementStatuses.AUTOMATIC_MANAGED) &&
        secondRemainingStart != null &&
        secondRemainingStart <= 0 &&
        secondRemainingEnd != null &&
        secondRemainingEnd > 0) ? (
        <>
          {client && (
            <PubNubProvider client={client}>
              <Overlay
                msgChannel={clientData?.msgChannel}
                uuid={clientData?.uuid}
                participation={participation}
                session={session}
                argsTC={argsTC}
              />
            </PubNubProvider>
          )}
        </>
      ) : null}
      {session?.manualManagementStatus ===
        constants.agendaSessionManualManagementStatuses.MANUAL_MANAGED ||
      ((session?.manualManagementStatus === null ||
        session?.manualManagementStatus ===
          constants.agendaSessionManualManagementStatuses.AUTOMATIC_MANAGED) &&
        secondRemainingStart != null &&
        secondRemainingStart > 0) ? (
        <CountdownOverlay
          isManualManaged={
            session?.manualManagementStatus ===
            constants.agendaSessionManualManagementStatuses.MANUAL_MANAGED
          }
          totalTime={counterTotalTime}
          initialRemainingTime={secondRemainingStart}
          onComplete={() => setSecondRemainingStart(-1)}
        />
      ) : null}
      {session?.manualManagementStatus ===
        constants.agendaSessionManualManagementStatuses.STOPPED ||
      ((session?.manualManagementStatus === null ||
        session?.manualManagementStatus ===
          constants.agendaSessionManualManagementStatuses.AUTOMATIC_MANAGED) &&
        secondRemainingEnd != null &&
        secondRemainingEnd <= 0) ? (
        <EndSessionOverlay />
      ) : null}
    </div>
  ) : (
    <></>
  );
};

export default Webinar;
