import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const Boop = ({ rotation = 0, timing = 150, children }) => {
  const [isBooped, setIsBooped] = useState(false);

  const style = useSpring({
    display: 'inline-block',
    transform: isBooped ? `rotate(${rotation}deg)` : 'rotate(0deg)',
    config: {
      tension: 1000,
      friction: 110,
    },
  });

  useEffect(() => {
    const boopTimeout = setInterval(() => {
      toggle();
    }, timing);

    return () => clearInterval(boopTimeout);
  }, [isBooped, timing]);

  const toggle = () => setIsBooped(!isBooped);

  return <animated.span style={style}>{children}</animated.span>;
};

export { Boop };
