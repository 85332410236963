import React from 'react';

import { AimTypography, theme } from '@aim/components';

const Surveycard = ({ item, onSelect, selected }) => {
  //Styles
  const selectedStyle = {
    background: 'transparent',
    border: `2px solid ${theme.colors.primary.yellow}`,
  };

  const notSelectedStyle = {
    background: 'rgb(248, 246, 250, 0.3)',
    border: '2px solid rgb(248, 246, 250, 0)',
  };

  const currentStyle =
    selected?.id === item?.id ? selectedStyle : notSelectedStyle;

  return (
    <div
      style={{
        top: 483,
        left: 332,
        height: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ...currentStyle,
      }}
      onClick={() => onSelect(item)}
    >
      <AimTypography
        textAlign="center"
        style={{
          font: 'normal normal normal 24px/25px Roboto',
          color: 'white',
        }}
      >
        {item.text}
      </AimTypography>
    </div>
  );
};

export default Surveycard;
