import { useEffect, useRef, useCallback } from 'react';

const defaultOptions = {
  capture: false,
  once: false,
  passive: false,
};

const createHandlerSetter = (handlerValue) => {
  const handlerRef = useRef(handlerValue);

  // since useRef accepts an initial-value only, this is needed to make sure
  handlerRef.current = handlerValue;

  const setHandler = useCallback((nextCallback) => {
    if (typeof nextCallback !== 'function') {
      throw new Error(
        "the argument supplied to the 'setHandler' function should be of type function"
      );
    }

    handlerRef.current = nextCallback;
  });

  return [handlerRef, setHandler];
};

/**
 * Accepts an event name then returns a callback setter for a function to be performed when the event triggers.
 */
const useGlobalEvent = (eventName, options = defaultOptions, fn) => {
  const [callbackRef, setCallbackRef] = createHandlerSetter(fn);
  const opts = { ...defaultOptions, ...(options || {}) };

  useEffect(() => {
    const cb = (event) => {
      if (callbackRef.current) {
        callbackRef.current(event);
      }
    };

    if (callbackRef.current && eventName && typeof window !== 'undefined') {
      window.addEventListener(eventName, cb, opts);
    }

    return () => {
      if (eventName && typeof window !== 'undefined') {
        window.removeEventListener(eventName, cb, opts);
      }
    };
  }, [eventName, options]);

  return setCallbackRef;
};

export { useGlobalEvent };
