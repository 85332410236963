import React from 'react';

import { AimImage, styled, theme } from '@aim/components';

const ImageWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  overflow: 'hidden',
});

const StyledImage = styled(AimImage)({
  objectFit: 'cover',
  width: '100vw',
  height: '100vh',
});

const ImageOverlay = styled('div')({
  position: 'absolute',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.colors.greyScale.black,
  opacity: 0.55,
  overflow: 'hidden',
  top: 0,
  left: 0,
});

const ImageBackground = ({ src, children, showShadow, ...rest }) => {
  return (
    <>
      <ImageWrapper>
        <StyledImage alt="background-image" src={src} {...{ ...rest }} />
        {children}
      </ImageWrapper>
      {showShadow ? <ImageOverlay /> : null}
    </>
  );
};

export default ImageBackground;
