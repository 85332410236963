import React from 'react';

import { useIntl } from 'react-intl';
import translation from './../utils/translation';

import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { useHistory, useParams } from 'react-router-dom';

import {
  AimIconAndTextButton,
  AimTypography,
  theme,
  styled,
  CustomIntl,
} from '@aim/components';
import { appState } from '@aim/common';

const peopleWatchingIconStyle = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
  marginLeft: 10,
  marginRight: 10,
};

const exitButtonStyle = {
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 5,
  paddingBottom: 5,
  marginLeft: 10,
  marginRight: 10,
};

const ControlContainer = styled('div')({
  color: theme.colors.greyScale.white,
  position: 'absolute',
  top: 25,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

const LeftTopControlsContainer = styled('div')({
  marginLeft: 25,
});

const RightTopControlsContainer = styled('div')({
  display: 'flex',
  marginRight: 25,
});

const TopControlsComponent = ({
  peopleWatching,
  hideWatching,
  hideExit,
  hasWebinarCounterUser,
}) => {
  const history = useHistory();
  const { eventId, agendaSessionId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.topControls(intl);

  const isAdminUser = appState.user
    .getValue()
    ?.awsUser?.groups.includes('admin');

  return (
    <ControlContainer>
      <LeftTopControlsContainer>
        <AimIconAndTextButton
          variant="none"
          text={''}
          onClick={() =>
            history.push(
              `/events/${eventId}/agenda/sessions/${agendaSessionId}`
            )
          }
        >
          <ArrowBack
            fontSize={'large'}
            htmlColor={theme.colors.greyScale.white}
          />
        </AimIconAndTextButton>
      </LeftTopControlsContainer>

      <RightTopControlsContainer>
        {/* PEOPLE WATCHING BUTTON */}
        {!hideWatching && (isAdminUser || hasWebinarCounterUser) && (
          <AimIconAndTextButton
            variant="darkGreyFill"
            style={peopleWatchingIconStyle}
            text={
              <AimTypography style={{ color: theme.colors.greyScale.white }}>
                {peopleWatching}
              </AimTypography>
            }
            onClick={() => console.log('on exit')}
          >
            <VisibilityIcon htmlColor={theme.colors.greyScale.white} />
          </AimIconAndTextButton>
        )}

        {/* EXIT BUTTON */}
        {!hideExit ? (
          <AimIconAndTextButton
            variant="brightRedFill"
            style={exitButtonStyle}
            text={
              <AimTypography style={{ color: theme.colors.greyScale.white }}>
                {i18n.buttons.exit.label}
              </AimTypography>
            }
            onClick={() =>
              history.push(
                `/events/${eventId}/agenda/sessions/${agendaSessionId}`
              )
            }
          />
        ) : null}
      </RightTopControlsContainer>
    </ControlContainer>
  );
};

const TopControls = React.memo(TopControlsComponent);

export default TopControls;
