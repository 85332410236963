import React from 'react';

import Countdown from './Countdown';
import TopControls from '../../components/TopControls';

const CountdownOverlay = ({
  initialRemainingTime,
  totalTime,
  onComplete,
  isManualManaged,
}) => {
  return (
    <div
      style={{
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }}
    >
      <TopControls hideWatching />
      <Countdown
        isManualManaged={isManualManaged}
        totalTime={totalTime}
        initialRemainingTime={initialRemainingTime}
        onComplete={onComplete}
      />
    </div>
  );
};

export default CountdownOverlay;
