import React from 'react';

import { useIntl } from 'react-intl';
import translation from '../../utils/translation';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
  AimTypography,
  AimIconAndTextButton,
  theme,
  styled,
  CustomIntl,
} from '@aim/components';

import TopControls from '../../components/TopControls';
import { useHistory, useParams } from 'react-router-dom';

const StyledButton = styled(AimIconAndTextButton)({
  color: theme.colors.greyScale.white,
  marginTop: 30,
});

const MainContainer = styled('div')({
  margin: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  width: '75%',
});

const EndSessionOverlay = () => {
  const { eventId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.endOverlay(intl);

  return (
    <div
      style={{
        zIndex: 10,
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }}
    >
      <MainContainer>
        <AimTypography variant="h1Regular">
          {i18n.firstMessage.label}
        </AimTypography>
        <AimTypography variant="h1Regular">
          {i18n.secondMessage.label}
        </AimTypography>
        <StyledButton
          onClick={(e) => history.push(`/events/${eventId}/agenda`)}
        >
          <ArrowForwardIcon />
          <AimTypography variant="text">
            {i18n.goToSessionsButton.label}
          </AimTypography>
        </StyledButton>
      </MainContainer>
      <TopControls hideWatching hideExit />
    </div>
  );
};

export default EndSessionOverlay;
