export const minTwoDigits = (n) => {
  return (n < 10 ? '0' : '') + n;
};

export const extractParticipationUserInfo = (participation) => ({
  id: participation.id,
  user: {
    givenName: participation?.givenName,
    familyName: participation?.familyName,
    image: participation?.profileImage,
  },
});
