import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import translation from './../../utils/translation';
import { fetchTC } from './../../utils/tcUtility';
import SurveyCard from './SurveyCard';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  theme,
} from '@aim/components';

const Survey = ({ onSend, eventId, agendaSessionId, argsTC }) => {
  //State
  const [data, setData] = useState();
  const [selected, setSelected] = useState();

  //Effects
  useEffect(() => {
    if (eventId) fetchPoll();
  }, []);

  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = translation.surveyDialogContent(intl);

  //Functions
  const onSendAnswer = async () => {
    const input = {
      body: {
        type: 'answerPoll',
        argsTC: argsTC,
        eventId: eventId,
        sessionId: agendaSessionId,
        idAnswer: selected.id,
        idPoll: data.idPoll,
      },
    };
    await fetchTC(input, (res) => {
      const nextQuestion = res.result[0];

      setSelected(null);
      setData(res.result[0]);

      if (!nextQuestion) onSend && onSend();
    });
  };

  const fetchPoll = async () => {
    const input = {
      body: {
        type: 'getPoll',
        argsTC: argsTC,
        eventId: eventId,
      },
    };
    await fetchTC(input, (res) => {
      setData(res.result[0]);
    });
  };

  return (
    <Grid
      container
      style={{
        color: theme.colors.greyScale.white,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        textAlign: 'center',
      }}
    >
      <Grid item xs={12}>
        <AimTypography
          style={{ font: 'normal normal 300 54px/36px Roboto' }}
          textAlign="center"
        >
          {data?.question}
        </AimTypography>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '2%' }}>
        {data?.answers ? (
          <Grid container style={{ width: '50%', margin: 'auto' }} spacing={1}>
            {data?.answers?.map((i) => (
              <Grid key={i.id} item xs={6}>
                <SurveyCard
                  item={i}
                  onSelect={setSelected}
                  selected={selected}
                />
              </Grid>
            ))}
            {
              <Grid xs={12} style={{ textAlign: 'right' }}>
                <AimIconAndTextButton
                  style={{ marginRight: 20 }}
                  variant="yellow"
                  disabled={!selected}
                  text={
                    <AimTypography style={{ color: 'white' }}>
                      {i18n.buttons.send.label?.toUpperCase() ||
                        i18n.buttons.send.label}
                    </AimTypography>
                  }
                  onClick={onSendAnswer}
                >
                  <ArrowRightAltIcon htmlColor="white" />
                </AimIconAndTextButton>
              </Grid>
            }
          </Grid>
        ) : (
          <Grid
            container
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            spacing={1}
          >
            <AimTypography
              style={{ font: 'normal normal 300 54px/36px Roboto' }}
              textAlign="center"
            >
              {!data || data?.length === 0
                ? `${i18n.alert.noMoreQuestion.label}`
                : null}
            </AimTypography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Survey;
